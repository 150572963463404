import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  FiSearch,
  FiPlus,
} from 'react-icons/fi';

import api from '../../services/api';

import {
  Container,
  CirculoCarregandoDiv,
  CirculoCarregando,
  Header,
  HeaderContent,
  Content,
  LinhaBuscar,
  Buscar,
  BotaoPesquisarClientes,
  ListaCartoes,
  Cartao,
  CabecalhoCartao,
  AreaBotoes,
  Botao,
} from './styles';

import logoImg from '../../assets/logo-sizex2.jpg';

import { useAuth } from '../../context/AuthContext';
import { useConfigs } from '../../context/ConfigsContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import { useToast } from '../../context/ToastContext';

interface ClienteSimples {
  id: string;
  nome: string;
  fone: string;
  email: string;
  bloqueado: boolean;
}

interface Cliente {
    id: string;
    nome: string;
    fantasia: string;
    ativo: 'S' | 'N';
    id_origem: string;
    empresa_id: string;
    cnpjcpf: string;
    tipo: string;
    cep: string;
    cidade: string;
    estado: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    ibge: string;
    tipo_pessoa: string;
    data: Date;
    usuario: string;
    consumidor: number;
    inscricao_estadual: string;
    tipo_contribuinte: number;
    pais_id: number;
    tipo_preco: string;
    forma_pag_id: number;
    seguimento: string;
    vr_lim_credito: number;
    sem_juros: boolean;
    filial: boolean;
    vendedor_id: number;
    saldo_devedor: number;
    bloqueado: boolean;
    ultima_compra: string;
  }

const PesquisaClientes: React.FC = () => {
  const { addToast } = useToast();
  const { signOut, user } = useAuth();
  const history = useHistory();
  // const toast = useToast();
  const { cores, lerConfig } = useConfigs();
  const { lerIndexedDB, gravarIndexedDB } = useIndexedDB();
  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(undefined);
  const [carregarComDelay, setCarregarComDelay] = useState(false);

  const inputBuscar = useRef<HTMLInputElement>(null);

  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [clientesFiltrados, setClientesFiltrados] = useState<Cliente[]>([]);

  const [textoBuscar, setTextoBuscar] = useState<string>('');
  // const [pesquisaRealizada, setPesquisaRealizada] = useState<string>('');
  // const [pesquisaComResultados, setPesquisaComResultados] = useState(true);

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  useEffect(() => {
    // IMAGENS DINÂMICAS
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api.get<string>('/empresas/configs-caminho-arquivo', { params: {
        arquivo: configLogo
      } }).then(response => {
        setLogoDinamico(response.data);
      });
    } else {
      configLogo = undefined;
    }

    api.get<Cliente[]>('/pessoas').then((response) => {
        setClientesFiltrados(response.data);
        setClientes(response.data);
    });

    // Delay pra dar tempo de carregar as cores do sistema quando não utiliza login
    setTimeout(() => {
      setCarregarComDelay(true);
    }, 200);

  }, [signOut, lerConfig, lerIndexedDB, user.empresa.url_frontend]);

  const buscar = useCallback(async() => {
    // setPesquisaComResultados(true);
    // setPesquisaRealizada('');

    // let conteudoBuscar = inputBuscar.current?.value;
    let conteudoBuscar = textoBuscar;
    if (conteudoBuscar === '') {
        // toast.addToast({
        //     title: 'Falha na pesquisa',
        //     description: 'Digite o nome ou nome fantasia do cliente a buscar'
        // });
        setClientesFiltrados(clientes);
        return;
    }
    conteudoBuscar = String(conteudoBuscar).toUpperCase();

    setClientesFiltrados([]);

    // const resposta = await api.get<Cliente[]>('/cliente/por-nome', {
    //   params: {
    //     nome: conteudoBuscar
    //   }
    // });
    

    const clientesFiltradosTemp = clientes.filter(cliente =>
        cliente.nome?.toUpperCase().includes(conteudoBuscar) ||
        cliente.fantasia?.toUpperCase().includes(conteudoBuscar) ||
        cliente.endereco?.toUpperCase().includes(conteudoBuscar)
    );

    setClientesFiltrados(clientesFiltradosTemp);
    // setPesquisaRealizada(conteudoBuscar);
    // setTextoBuscar('');
    // if (resposta.data.length === 0) {
    //   setPesquisaComResultados(false);
    // }
  }, [textoBuscar, clientes]);

  const aoPressionarTeclaBuscar = useCallback(async(e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      await buscar();
    }
  }, [buscar]);

  const aoSelecionar = useCallback(async (clienteSelecionado: Cliente) => {    
    const clienteSimplesTemp: ClienteSimples = {
      id: clienteSelecionado.id,
      nome: clienteSelecionado.fantasia,
      email: '',
      fone: '',
      bloqueado: clienteSelecionado.bloqueado === null ? false : clienteSelecionado.bloqueado
    };

    if (clienteSimplesTemp.bloqueado) {
      addToast({
        type: "error", 
        title: 'Cliente Bloqueado', 
        description: 'Não foi possível selecionar o cliente pois ele está bloqueado',
      });
      return
    }

    gravarIndexedDB(`${user.empresa.url_frontend}:carrinho-cliente-simples`, clienteSimplesTemp);

    history.push('carrinho');
  }, [gravarIndexedDB, user.empresa.url_frontend, history, addToast]);

  const incluirCliente = useCallback(() => {
    history.push('/cliente');
  }, [history]);

  return ( carregarComDelay ?
    <Container cores={cores}>
      <Header cores={cores} >
        <HeaderContent cores={cores}>
          <Link to="/home">
            <img src={logoDinamico ? logoDinamico : logoImg} alt="Logo"/>
          </Link>
        </HeaderContent>
      </Header>

      <Content>
        <LinhaBuscar>
          <Buscar cores={cores}>
            <input
              type="text"
              placeholder="Pesquisar"
              onKeyPress={aoPressionarTeclaBuscar}
              value={textoBuscar}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextoBuscar(e.target.value)}
              ref={inputBuscar}
            />
            <button type='button' onClick={buscar}><FiSearch size={28} /></button>
          </Buscar>
          <BotaoPesquisarClientes cores={cores}>
            <button
              type="button"
              onClick={incluirCliente}
              // title="Incluir Cliente"
            >
              <div>
                <FiPlus />
              </div>
            </button>
          </BotaoPesquisarClientes>
        </LinhaBuscar>

        <ListaCartoes>
            {clientesFiltrados.map(cliente => (
            <Cartao key={cliente.id}>
                <CabecalhoCartao>
                <h2>{cliente.fantasia}</h2>
                {/* {iconeStatus(cliente)} */}
                {/* <FiAlertTriangle size={30} color="#595959"/> */}
                </CabecalhoCartao>

                {cliente.nome !== cliente.fantasia && <p>NOME/RAZÃO: {cliente.nome}</p> }
                {cliente.cnpjcpf && <p>CNPJ/CPF: {cliente.cnpjcpf}</p> }
                {cliente.endereco && <p>ENDEREÇO: {cliente.endereco}, {cliente.numero} - {cliente.bairro}</p> }
                {cliente.complemento && <p>COMPLEMENTO: {cliente.complemento}</p> }
                {cliente.cidade && <p>{cliente.cidade}-{cliente.estado}</p> }
                {/* <p>ÚLTIMA CONSULTA: {dataFormatada(cliente.ultimaConsultaLiberacao)}</p> */}
                {(cliente.ultima_compra !== '' || cliente.saldo_devedor > 0) && <br></br>}
                {cliente.ultima_compra !== '' && <p>ÚLTIMA COMPRA: {cliente.ultima_compra}</p>}
                {cliente.saldo_devedor > 0 && <p><b>SALDO DEVEDOR: {formatter.format(cliente.saldo_devedor)}</b></p>}

                <AreaBotoes>
                <Botao
                    type="button"
                    variant="contained"
                    onClick={() => aoSelecionar(cliente)}
                >
                    SELECIONAR
                </Botao>
                </AreaBotoes>
            </Cartao>
            ))}
        </ListaCartoes>
      </Content>

    </Container> : <CirculoCarregandoDiv><CirculoCarregando cores={cores} /></CirculoCarregandoDiv>
  );
};

export default PesquisaClientes;
