import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';

import {
  FiPower,
  FiUser,
  FiChevronLeft,
  FiChevronRight,
  FiTrash2,
  FiShoppingCart,
  FiSearch,
  FiList,
} from 'react-icons/fi';

import api from '../../services/api';

import {
  Container,
  CirculoCarregandoDiv,
  CirculoCarregando,
  Header,
  HeaderContent,
  Profile,
  Content,
  GaleriaProdutos,
  ProdutoBox,
  ProdutoAnimatedBox,
  ProdutoImagem,
  ProdutoTitulo,
  ProdutoQtde,
  ProdutoPreco,
  ProdutoExcluir,
  TotalGaleria,
  BoxDadosClienteSimples,
  InputNomeClienteSimples,
  InputFoneClienteSimples,
  InputEmailClienteSimples,
  InputObsClienteSimples,
  BotaoFinalizar,
  BoxDadosClienteLogin,
  AreaClienteLogin,
  InputNomeClienteLogin,
  BotaoPesquisarClientes,
  InputObsClienteLogin,
} from './styles';

import logoImg from '../../assets/logo-sizex2.jpg';
import defaultAvatar from '../../assets/default-avatar.png';
import produtoSemImagemImg from '../../assets/produto-sem-imagem.png';

import { useAuth } from '../../context/AuthContext';
// import { useToast } from '../../context/ToastContext';
import { useConfigs } from '../../context/ConfigsContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import { useToast } from '../../context/ToastContext';
import { AxiosResponse } from 'axios';

interface Produto {
  id: string;
  descricao: string;
  preco: number;
  imagem_url: string;
  grupo: string;
  qtdeComprar: number;
  animacao: boolean;
}

interface ClienteSimples {
  id: string;
  nome: string;
  fone: string;
  email: string;
  bloqueado: boolean;
}

interface VendaSimples {
  nome_cliente: string;
  email_cliente: string;
  fone_cliente: string;
  obs: string;
  itens: VendaItens[];
}

interface VendaLogin {
  cliente_id: string;
  obs: string;
  itens: VendaItens[];
}

interface VendaItens {
  produto_id: string;
  qtde: number;
  preco: number;
}

const Carrinho: React.FC = () => {
  const { addToast } = useToast();
  const { signOut, user } = useAuth();
  const history = useHistory();
  // const toast = useToast();
  const { cores, lerConfig } = useConfigs();
  const { lerIndexedDB, gravarIndexedDB, excluirIndexedDB } = useIndexedDB();
  const [carrinho, setCarrinho] = useState<Produto[]>([]);
  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(undefined);
  const [carregarComDelay, setCarregarComDelay] = useState(false);

  // States para o form simples
  const [bloqueado, setBloqueado] = useState<boolean>(false)
  const [nomeClienteSimples, setNomeClienteSimples] = useState<string>('');
  const [nomeClienteSimplesErro, setNomeClienteSimplesErro] = useState<string>('');
  const [foneClienteSimples, setFoneClienteSimples] = useState<string>('');
  const [foneClienteSimplesErro, setFoneClienteSimplesErro] = useState<string>('');
  const [emailClienteSimples, setEmailClienteSimples] = useState<string>('');
  const [emailClienteSimplesErro, setEmailClienteSimplesErro] = useState<string>('');
  const [obsClienteSimples, setObsClienteSimples] = useState<string>('');
  const [obsClienteSimplesErro, setObsClienteSimplesErro] = useState<string>('');
  // Refs para o form simples
  const inputNomeClienteSimples = useRef<HTMLInputElement>(null);
  const inputFoneClienteSimples = useRef<HTMLInputElement>(null);
  const inputEmailClienteSimples = useRef<HTMLInputElement>(null);
  const inputObsClienteSimples = useRef<HTMLInputElement>(null);

  // States para o form de empresa com login
  const [idClienteLogin, setIdClienteLogin] = useState<string>('');
  // const [nomeClienteLogin, setNomeClienteLogin] = useState<string>('');
  // const [nomeClienteLoginErro, setNomeClienteLoginErro] = useState<string>('');
  // const [obsClienteLogin, setObsClienteLogin] = useState<string>('');
  // const [obsClienteLoginErro, setObsClienteLoginErro] = useState<string>('');
  // Refs para o form de empresa com login
  const inputNomeClienteLogin = useRef<HTMLInputElement>(null);
  const inputObsClienteLogin = useRef<HTMLInputElement>(null);

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  useEffect(() => {
    // IMAGENS DINÂMICAS
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api.get<string>('/empresas/configs-caminho-arquivo', { params: {
        arquivo: configLogo
      } }).then(response => {
        setLogoDinamico(response.data);
      });
    } else {
      configLogo = undefined;
    }

    // api.get<Produto[]>('/produtos/destaques').then((response) => {
    //   setProdutos(retornaProdutos(response.data));
    // });

    // excluirIndexedDB(`${user.empresa.url_frontend}:carrinho-compras`);
    lerIndexedDB<Produto[]>(`${user.empresa.url_frontend}:carrinho-compras`)
      .then((produtosIndexedDB) => {
        if (produtosIndexedDB === null) {
          setCarrinho([]);
        } else {
          // percorre carrinho mudando animacao para false
          produtosIndexedDB = produtosIndexedDB.map(prodMap => ({
            ...prodMap,
            animacao: false,
          }));

          setCarrinho(produtosIndexedDB);
        }
      });

    // Carregar dados salvo do cliente simples
    lerIndexedDB<ClienteSimples>(`${user.empresa.url_frontend}:carrinho-cliente-simples`)
      .then((clienteSimplesIndexedDB) => {
        if (clienteSimplesIndexedDB !== null) {
          setIdClienteLogin(clienteSimplesIndexedDB.id);
          setBloqueado(clienteSimplesIndexedDB.bloqueado);
          setNomeClienteSimples(clienteSimplesIndexedDB.nome);
          setFoneClienteSimples(clienteSimplesIndexedDB.fone);
          setEmailClienteSimples(clienteSimplesIndexedDB.email);

          // if (clienteSimplesIndexedDB.id) {
          //   if (clienteSimplesIndexedDB.id !== '') {

          //   }
          // }

        }
      });

    // Delay pra dar tempo de carregar as cores do sistema quando não utiliza login
    setTimeout(() => {
      setCarregarComDelay(true);
    }, 200);

  }, [signOut, lerConfig, lerIndexedDB, user.empresa.url_frontend]);

  const abrirProfile = useCallback(() => {
    history.push('/profile');
  }, [history]);

  const abrirVendas = useCallback(() => {
    history.push('/vendas');
  }, [history]);

  const setProdutoQtde = useCallback((valor: string, produtoId: string) => {
    const carrinhoTemp = carrinho.map((item) =>
      item.id === produtoId ? {...item, qtdeComprar: Number(valor)} : item );
    setCarrinho(carrinhoTemp);
    gravarIndexedDB(`${user.empresa.url_frontend}:carrinho-compras`, carrinhoTemp);
  }, [carrinho, gravarIndexedDB, user.empresa.url_frontend]);

  const incrementarProdutoQtde = useCallback((valor: number, produtoId: string) => {
    const carrinhoTemp = carrinho.map((item) =>
      item.id === produtoId ? {...item, qtdeComprar: (Number(valor) + 1)} : item );
    setCarrinho(carrinhoTemp);
    gravarIndexedDB(`${user.empresa.url_frontend}:carrinho-compras`, carrinhoTemp);
  }, [carrinho, gravarIndexedDB, user.empresa.url_frontend]);

  const decrementarProdutoQtde = useCallback((valor: number, produtoId: string) => {
    if (Number(valor) > 1) {
      const carrinhoTemp = carrinho.map((item) =>
        item.id === produtoId ? {...item, qtdeComprar: (Number(valor) - 1)} : item );
      setCarrinho(carrinhoTemp);
      gravarIndexedDB(`${user.empresa.url_frontend}:carrinho-compras`, carrinhoTemp);
    }
  }, [carrinho, gravarIndexedDB, user.empresa.url_frontend]);

  const excluir = useCallback((produtoId: string) => {
    const carrinhoTemp = [...carrinho];
    const indiceProduto = carrinhoTemp.findIndex(prod => prod.id === produtoId);

    if (indiceProduto >= 0) {
      carrinhoTemp[indiceProduto].animacao = true;
      setCarrinho(carrinhoTemp);
      setTimeout(() => {
        // carrinhoTemp[indiceProduto].animacao = false;
        // setCarrinho(carrinhoTemp);

        const carrinhoTempNovo = [...carrinhoTemp];
        carrinhoTempNovo.splice(indiceProduto, 1);
        setCarrinho(carrinhoTempNovo);
        gravarIndexedDB(`${user.empresa.url_frontend}:carrinho-compras`, carrinhoTempNovo);

        if (carrinhoTempNovo.length === 0) {
          history.push('home');
        }
      }, 400);
    }
  }, [carrinho, gravarIndexedDB, user.empresa.url_frontend, history]);

  const totalCarrinho = useMemo(() => {
    if (!carrinho) {
      return 0;
    }
    return carrinho.reduce((soma, item) => soma + (item.qtdeComprar * item.preco), 0);
  }, [carrinho]);

  const aoAlterarNomeClienteSimples = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNomeClienteSimples(event.target.value);
    setNomeClienteSimplesErro('');
  }, [setNomeClienteSimples, setNomeClienteSimplesErro]);

  const aoAlterarFoneClienteSimples = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFoneClienteSimples(event.target.value);
    setFoneClienteSimplesErro('');
  }, [setFoneClienteSimples, setFoneClienteSimplesErro]);

  const aoAlterarEmailClienteSimples = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailClienteSimples(event.target.value);
    setEmailClienteSimplesErro('');
  }, [setEmailClienteSimples, setEmailClienteSimplesErro]);

  const aoAlterarObsClienteSimples = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setObsClienteSimples(event.target.value);
    setObsClienteSimplesErro('');
  }, [setObsClienteSimples, setObsClienteSimplesErro]);

  const aoFinalizar = useCallback(async () => {
    setNomeClienteSimplesErro('');
    setFoneClienteSimplesErro('');
    setEmailClienteSimplesErro('');

    let erroValidacao = false;
    if (user.empresa.utiliza_login === 'S') {
      if (idClienteLogin === '') {
        setNomeClienteSimplesErro('Cliente é obrigatório!');
        erroValidacao = true;
      } else {
        if (!idClienteLogin) {
          setNomeClienteSimplesErro('Cliente é obrigatório!');
          erroValidacao = true;
        }
      }
    } else {
      if (nomeClienteSimples === '') {
        setNomeClienteSimplesErro('Informação obrigatória!');
        erroValidacao = true;
      }
      if (foneClienteSimples === '') {
        setFoneClienteSimplesErro('Informação obrigatória!');
        erroValidacao = true;
      }
      // if (emailClienteSimples === '') {
      //   setEmailClienteSimplesErro('Informação obrigatória!');
      //   erroValidacao = true;
      // }
    }

    if (bloqueado) {
      addToast({
        type: "error", 
        title: 'Cliente Bloqueado', 
        description: 'Não foi possível finalizar a venda pois o cliente está bloqueado',
      });
      return
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description: 'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return;
    }

    let retornoGravacao: AxiosResponse<any>;
    if (user.empresa.utiliza_login === 'S') {
      const vendaGravar: VendaLogin = {
        cliente_id: idClienteLogin,
        obs: obsClienteSimples,
        itens: carrinho.map(prodMap => {
          return {
            produto_id: prodMap.id,
            qtde: prodMap.qtdeComprar,
            preco: prodMap.preco,
          };
        }),
      };
  
      // console.log(vendaGravar);
      retornoGravacao = await api.post('/vendas/empresa-login', vendaGravar);
    } else {
      const vendaGravar: VendaSimples = {
        nome_cliente: nomeClienteSimples,
        email_cliente: emailClienteSimples,
        fone_cliente: foneClienteSimples,
        obs: obsClienteSimples,
        itens: carrinho.map(prodMap => {
          return {
            produto_id: prodMap.id,
            qtde: prodMap.qtdeComprar,
            preco: prodMap.preco,
          };
        }),
      };
  
      // console.log(vendaGravar);
      retornoGravacao = await api.post('/vendas', vendaGravar);
    }
    
    if (retornoGravacao.status !== 200) {
      addToast({
        type: 'error' ,
        title: 'Falha ao finalizar a compra! Tente novamente em alguns minutos!',
      });
    } else {
      addToast({
        type: 'success',
        title: 'Compra finalizada com sucesso!!!',
      });

      // Grava dados do cliente para reutilizar, reutilizar se não for empresa com login
      const clienteSimplesTemp: ClienteSimples = {
        id: '',
        nome: nomeClienteSimples,
        email: emailClienteSimples,
        fone: foneClienteSimples,
        bloqueado
      };
      if (user.empresa.utiliza_login === 'S') {
        excluirIndexedDB(`${user.empresa.url_frontend}:carrinho-cliente-simples`);
      } else {
        gravarIndexedDB(`${user.empresa.url_frontend}:carrinho-cliente-simples`, clienteSimplesTemp);
      }

      // Limpa carrinho
      excluirIndexedDB(`${user.empresa.url_frontend}:carrinho-compras`);

      // Redireciona para a página home
      history.push('home');
    }

  }, [
      idClienteLogin,
      nomeClienteSimples,
      foneClienteSimples,
      emailClienteSimples,
      obsClienteSimples,
      addToast,
      gravarIndexedDB,
      excluirIndexedDB,
      history,
      user.empresa.url_frontend,
      carrinho,
      user.empresa.utiliza_login,
      bloqueado
    ]
  );

  const aoAlterarObsClienteLogin = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setObsClienteSimples(event.target.value);
    setObsClienteSimplesErro('');
  }, [setObsClienteSimples, setObsClienteSimplesErro]);

  const pesquisarClientes = useCallback(() => {
    history.push('/pesquisa-clientes');
  }, [history]);

  return ( carregarComDelay ?
    <Container cores={cores}>
      <Header cores={cores} >
        <HeaderContent cores={cores}>
          <Link to="/home">
            <img src={logoDinamico ? logoDinamico : logoImg} alt="Logo"/>
          </Link>

          { user.empresa.utiliza_login === 'S' &&
            <Profile cores={cores}>
              <img src={!user.avatar_url ? defaultAvatar : user.avatar_url} alt={user.name} />
              <ul>
                <li><button type="button" onClick={abrirVendas}><FiList /><span>Vendas</span></button></li>
                <li><button type="button" onClick={abrirProfile}><FiUser /><span>Perfil</span></button></li>
                <li><button type="button" onClick={signOut}><FiPower /><span>Sair</span></button></li>
              </ul>
              <div>
                <span>Bem vindo,</span>
                <strong>{user.name}</strong>
              </div>
            </Profile>
          }

        </HeaderContent>
      </Header>

      <Content>
          <GaleriaProdutos>
            { carrinho.map((produto) => (
              <ProdutoBox key={produto.id}>
                <ProdutoAnimatedBox animacao={produto.animacao}>
                  <ProdutoImagem src={produto.imagem_url ? produto.imagem_url : produtoSemImagemImg} alt={produto.descricao} />
                  <section>
                    <ProdutoTitulo cores={cores}>{produto.descricao}</ProdutoTitulo>
                    <ProdutoQtde cores={cores}>
                      {/* <p>QTDE: </p> */}
                      <button
                        type="button"
                        onClick={() => decrementarProdutoQtde(produto.qtdeComprar, produto.id)}
                      >
                        <FiChevronLeft />
                      </button>
                      <input
                        type="Number"
                        step="1"
                        value={produto.qtdeComprar}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setProdutoQtde(e.target.value, produto.id)}
                      />
                      <button
                        type="button"
                        onClick={() => incrementarProdutoQtde(produto.qtdeComprar, produto.id)}
                      >
                        <FiChevronRight />
                      </button>

                    </ProdutoQtde>
                    <ProdutoPreco cores={cores}>
                      <span>{formatter.format(produto.preco)}</span>
                    </ProdutoPreco>
                  </section>
                  <ProdutoExcluir cores={cores}>
                    <FiTrash2 onClick={() => excluir(produto.id)} />
                  </ProdutoExcluir>
                </ProdutoAnimatedBox>
              </ProdutoBox>
            ))}

            <TotalGaleria cores={cores}>
              <hr />
              <div>
                <span>TOTAL DOS ITENS:</span>
                <strong>{formatter.format(totalCarrinho)}</strong>
              </div>
            </TotalGaleria>


            { user.empresa.utiliza_login === 'N' &&         
            <>
              <BoxDadosClienteSimples>
                <InputNomeClienteSimples
                  variant="outlined"
                  margin="normal"
                  // required
                  // fullWidth
                  id="nomeClienteSimples"
                  label="Nome do Cliente"
                  name="nomeClienteSimples"
                  // autoComplete="email"
                  inputRef={inputNomeClienteSimples}
                  value={nomeClienteSimples}
                  onChange={aoAlterarNomeClienteSimples}
                  error={nomeClienteSimplesErro !== ''}
                  helperText={nomeClienteSimplesErro}
                />

                <div>
                  <InputFoneClienteSimples
                    variant="outlined"
                    margin="normal"
                    // required
                    // fullWidth
                    id="foneClienteSimples"
                    label="Fone"
                    name="foneClienteSimples"
                    // autoComplete="email"
                    inputRef={inputFoneClienteSimples}
                    value={foneClienteSimples}
                    onChange={aoAlterarFoneClienteSimples}
                    error={foneClienteSimplesErro !== ''}
                    helperText={foneClienteSimplesErro}
                  />

                  <InputEmailClienteSimples
                    variant="outlined"
                    margin="normal"
                    // required
                    // fullWidth
                    id="emailClienteSimples"
                    label="E-mail"
                    name="emailClienteSimples"
                    // autoComplete="email"
                    inputRef={inputEmailClienteSimples}
                    value={emailClienteSimples}
                    onChange={aoAlterarEmailClienteSimples}
                    error={emailClienteSimplesErro !== ''}
                    helperText={emailClienteSimplesErro}
                  />
                </div>

                <InputObsClienteSimples
                    variant="outlined"
                    margin="normal"
                    // required
                    // fullWidth
                    id="obsClienteSimples"
                    label="Observações"
                    name="obsClienteSimples"
                    multiline
                    rows={5}
                    // autoComplete="email"
                    inputRef={inputObsClienteSimples}
                    value={obsClienteSimples}
                    onChange={aoAlterarObsClienteSimples}
                    error={obsClienteSimplesErro !== ''}
                    helperText={obsClienteSimplesErro}
                  />
              </BoxDadosClienteSimples>

              <BotaoFinalizar cores={cores}>
                <button
                  type="button"
                  onClick={aoFinalizar}
                >
                  <div>
                    <FiShoppingCart />
                    <span>FINALIZAR</span>
                  </div>
                </button>
              </BotaoFinalizar>
            </>
            }

            { user.empresa.utiliza_login === 'S' &&         
            <>
              <BoxDadosClienteLogin>
                <AreaClienteLogin>
                  <InputNomeClienteLogin
                    variant="outlined"
                    margin="normal"
                    // required
                    // fullWidth
                    id="nomeClienteLogin"
                    label="Nome do Cliente"
                    name="nomeClienteLogin"
                    aria-readonly="true"
                    // autoComplete="email"
                    inputRef={inputNomeClienteLogin}
                    value={nomeClienteSimples}
                    // onChange={aoAlterarNomeClienteSimples}
                    error={nomeClienteSimplesErro !== ''}
                    helperText={nomeClienteSimplesErro}
                  />
                  <BotaoPesquisarClientes cores={cores}>
                    <button
                      type="button"
                      onClick={pesquisarClientes}
                      // title="Pesquisar Clientes"
                    >
                      <div>
                        <FiSearch />
                        {/* <span>FINALIZAR</span> */}
                      </div>
                    </button>
                  </BotaoPesquisarClientes>
                </AreaClienteLogin>
                
                <InputObsClienteLogin
                  variant="outlined"
                  margin="normal"
                  // required
                  // fullWidth
                  id="obsClienteLogin"
                  label="Observações"
                  name="obsClienteLogin"
                  multiline
                  rows={5}
                  // autoComplete="email"
                  inputRef={inputObsClienteLogin}
                  value={obsClienteSimples}
                  onChange={aoAlterarObsClienteLogin}
                  error={obsClienteSimplesErro !== ''}
                  helperText={obsClienteSimplesErro}
                />
              </BoxDadosClienteLogin>


              <BotaoFinalizar cores={cores}>
                <button
                  type="button"
                  onClick={aoFinalizar}
                >
                  <div>
                    <FiShoppingCart />
                    <span>FINALIZAR</span>
                  </div>
                </button>
              </BotaoFinalizar>
            </>
            }

          </GaleriaProdutos>
      </Content>

    </Container> : <CirculoCarregandoDiv><CirculoCarregando cores={cores} /></CirculoCarregandoDiv>
  );
};

export default Carrinho;
